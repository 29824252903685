body {
  background: rgb(20, 27, 45);
}
.add_bank {
  width: 80%;
  margin: 90px auto;
  background: #d7d7d7;
  padding: 30px;
  border-radius: 10px;
}
.error {
  color: red;
  font-size: 13px;
}
.navbar-nav a.active {
  background: #141b2d;
}
.navbar-nav .active .text-white {
  color: #ffffff !important;
}
#setting_sidebar button::after {
  width: 100%;
}
#setting_sidebar .dropdown-menu.show {
  position: unset;
  margin-top: 0px !important;
  background: transparent;
  margin: 0 40px 20px;
  padding: 0;
}
#setting_sidebar .dropdown-menu {
  background: transparent;
}
#setting_sidebar .dropdown-menu.show li a {
  background: transparent;
  color: #fff;
}
#setting_sidebar .dropdown-menu::before {
  position: unset;
  content: unset;
}

.gate_way tbody td {
  padding-left: 25px;
}

.custom-width-36 {
  white-space: unset !important;
  width: 36%;
}

.gate_way .text-danger {
  position: relative;
  top: 4px;
}
.pe-auto {
  cursor: pointer;
}

.add_input {
  gap: 20px;
}
.flex-both {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.file_upload input[type="file"]::file-selector-button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  text-indent: -9999px;
  width: 30px;
  white-space: nowrap;
}
.file_upload {
  position: relative;
}
.file_upload i {
  left: 0px;
  top: 0;
}
.file_upload svg {
  width: 25px !important;
}

.successfull span {
  position: absolute;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  top: 50%;
}
/* ::-webkit-scrollbar {
  height: 1px;
  width: 1px;
  background: #fff;
} */
::-webkit-scrollbar-thumb:horizontal {
  background: #fff;
}
.bg_viewpopup {
  z-index: 9999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}
.bg_viewpopup .viewFiles,
.successfull {
  transform: translate(-50%, -50%);
}
.cross_icon {
  width: 40px;
  height: 40px;
  top: 6px;
}
.cross_icon label {
  position: relative;
  top: -4px;
  font-size: 30px;
  color: #fff;
  right: 2px;
}

.cross_icon12 {
  width: 25px;
  height: 25px;
  top: -13px;
  right: -10px !important;
}
.cross_icon12 label {
  position: relative;
  top: -3px;
  font-size: 20px;
  color: #fff;
  right: 2px;
}

.navbar-collapse,
.viewFiles .files {
  width: 100%;
  overflow: auto;
  height: 100%;
  overflow-x: hidden !important;
}
.navbar-collapse::-webkit-scrollbar {
  width: 0.1px;
  height: 8px;
  background-color: #2e2e32;
}
.viewFiles .files::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #ffffff;
}

.viewFiles .files::-webkit-scrollbar-thumb {
  background: #ffffff;
}
.card .card-header .heading {
  font-size: 20px;
}
.card-footer span label {
  font-size: 13px;
  padding-right: 3px;
}
.card-footer span label:hover {
  color: #e43270 !important;
}

th,
td {
  font-size: 12px;
}
tbody {
  --bs-table-striped-bg: transparent !important;
}
.sidebarcollapse + .main-content {
  margin-left: 108px !important;
  z-index: 9999;
}
.heading_top + .container-fluid.py-3 {
  height: calc(100vh - 100px);
}
.sidebarcollapse ul li span,
.sidebarcollapse h6,
.sidebarcollapse .sidenav-header li {
  display: none;
}
.sidebarcollapse ul.navbar-nav ul {
  padding-left: 0;
}
.sidebarback {
  float: right;
  background: #fff;
  margin-right: 10px;
  color: #1f2a40;
  font-size: 25px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 0;
}
img.profile {
  max-width: 40px;
  max-height: 40px;
  border-radius: 10px;
  min-height: 40px;
  min-width: 40px;
  object-fit: cover;
}
.inner-form h3 {
  font-size: 23px;
  color: #fff;
  font-weight: 600;
  margin: 30px 0 7px;
}
rect {
  fill: #1f2a40;
}
text {
  fill: #fff;
}
table.page-table tr td a {
  font-weight: 500;
  background: #3e4396;
  color: #fff;
  padding: 5px 11px;
  border-radius: 4px;
}
.icon-list li a {
  background: transparent !important;
  padding: 0 !important;
  width: 100%;
  display: block;
}

.sidebarcollapse .sidebarback {
  right: 20px;
  position: relative;
}
input.text-start {
  background: #3e4396;
  border: 0;
  outline: none;
  border-radius: 8px;
  min-width: 310px;
  color: #fff;
  padding: 0 10px 0 35px;
}
html body .navbar-vertical.navbar-expand-xs.sidebarcollapse {
  max-width: max-content !important;
}

main.main-content .container-fluid.py-4 {
  min-height: 100vh;
}
.DateRangePicker {
  position: absolute !important;
  background: #fff;
  top: 100%;
  left: 0;
  z-index: 9999;
  border: 1px solid;
  border-radius: 5px;
}
select.form-select option {
  padding: 7px 5px;
}
.login-form input {
  color: #000;
}
.inner-form select option {
  background: #1f2a40;
}
.inner-agent .successfull.bg-white {
  position: static !important;
  transform: translate(0);
  width: 100% !important;
  max-width: 100% !important;
  margin-top: 20px;
}
.inner-agent .successfull.bg-white tr th {
  text-align: left;
}
.inner-agent .successfull.bg-white tr td {
  text-align: left;
  padding: 0.75rem 1.5rem;
  color: #fff;
}
.successfull::-webkit-scrollbar {
  width: 1em;
}

.successfull::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.successfull::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.popTable {
  height: 300px;
  overflow-y: scroll;
  min-height: 300px;
}
.popTable .cross_icon label {
  width: 96%;
  right: 0;
}

.popTable .cross_icon span {
  position: relative;
  top: 7px;
  font-size: 20px;
  color: #fff;
  right: 30px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: block;
  left: 100%;
  cursor: pointer;
}

.popTable .cross_icon {
  left: 0;
  width: 100%;
  transform: translate(0%, -100%);
  top: 19%;
  right: 0 !important;
}

.popTableTotal {
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 999;
  bottom: 0;
  width: 100%;
  left: 50%;
  transform: translate(-50%, 0%);
}

@media only screen and (max-width: 1240px) and (min-width: 767px) {
  .heading_top + form {
    width: 95% !important;
    margin-top: 20px !important;
  }
}

@media screen and (max-width: 767px) {
  select.form-select.text-start.btn.bg-gradient-dark.btn-md.mb-0.mx-2 {
    margin-top: 6px !important;
  }
  .mob-bb button {
    margin-bottom: 10px !important;
  }
  .mob-mb {
    margin-bottom: 10px !important;
  }
  .btn-expo button {
    width: 100%;
    margin-bottom: 10px;
  }
  .mob-margin-none {
    margin-right: 0px !important;
    margin-bottom: 0;
  }
  .main-content .position-relative.me-2 input {
    width: 100%;
  }
  .btn-expo {
    justify-content: space-between;
  }
  .btn-expo button {
    width: auto !important;
    display: block;
  }
  .main-content .position-relative.me-2 {
    margin-bottom: 10px;
    margin-right: 10px !important;
  }
  .dashboard-inner {
    margin-bottom: 15px;
  }
  .dateselect_navigation,
  .dateselect_navigation .d-flex {
    display: unset !important;
    float: unset !important;
  }
  .dateselect_navigation a button {
    width: 100%;
    margin: 0 !important;
  }
  .dateselect_navigation select.form-select {
    margin: 0 !important;
    margin-bottom: 4px !important;
  }
  .dateselect_navigation input {
    width: 100%;
    min-width: auto;
  }
  .table-responsive th,
  .h6 {
    font-size: 14px !important;
  }
  .dateselect_navigation .position-relative.bg-gradient-dark.rounded {
    display: flex !important;
    margin-bottom: 4px;
    height: 44px;
    margin: 7px 0px;
  }

  .card .card-header .heading,
  h6 {
    font-size: 14px;
  }
  .header_topbar.headeercollapse {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .heading_top + form {
    width: 96% !important;
    margin-top: 20px !important;
  }
  .user_add {
    display: unset !important;
  }
  .user_add a button {
    float: unset !important;
    width: 100%;
    justify-content: center;
  }
}

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: #000;
  border-color: #000;
  color: #fff;
}
.pagination > li > a {
  border: 1px solid #ccc;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #3e4396;
  border-color: #fff;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #2e2e32;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.header-back-btn {
  /* border: none; */
  margin-right: 10px;
  border-radius: 20px;
  /* background: transparent; */
}

.pagination-btn {
  gap: 15px !important;
  border-top: 3px solid #ddd;
  padding: 13px 0;
}
.pagination-btn ul.pagination {
  margin: unset;
}
.pagination-btn ul li a,
.pagination-btn ul li.previous a,
.pagination-btn ul li.next a {
  border-radius: 50%;
  margin: 0 5px;
  width: 35px;
  height: 35px;
  display: block;
  text-align: center;
  font-weight: 900;
  font-size: 14px;
  padding: 7px;
}

.header_topbar h6 {
  letter-spacing: 2px;
  font-size: 20px;
  font-weight: 300;
}
table.page-table {
  width: 100%;
  margin: 30px 0 0;
}
table.page-table tr th {
  background: rgb(62, 67, 150);
  padding: 14px 24px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-transform: capitalize !important;
}
table.page-table tr td {
  background: rgb(31 42 64);
  padding: 7px 24px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}
input {
  outline: none;
  box-shadow: none !important;
}
button.header-back-btn {
  background: #fff;
  border: 0;
  width: 35px;
  height: 35px;
}
form.page-from {
  padding: 15px 15px;
}
.successfullModal {
  background: #1f2a40 !important;
}
.successfullModal .modal-header h5 {
  color: #fff;
}
.successfullModal .page-from {
  padding: 10px;
}
.successfullModal .modal-body p {
  color: #ccc;
}
.successfullModal .modal-body p span {
  position: relative;
  left: unset;
  transform: unset;
  color: #fff;
  cursor: pointer;
}
.successfullModal .cross-btns {
  color: #fff;
  font-size: 29px !important;
}
.successfullModal .modal-header {
  justify-content: center;
}
.successfullModal .inner-form button {
  margin: 0 auto;
}
.successfullModal .inner-form {
  margin-top: 0;
}
.inner-form {
  padding: 23px;
  border-radius: 10px;
  background: #1f2a40;
  margin-top: 20px;
}
.dashboad-box {
  padding: 29px 15px;
}
.dashboard-inner {
  background: #1f2a40;
  padding: 30px;
  border-radius: 13px;
}
.dashboard-inner h5 {
  color: #fff;
  font-weight: 500;
}
.dashboard-inner span {
  color: #fff;
  font-size: 36px;
  font-weight: 700;
  margin: 0;
}
.log-logo h4 {
  color: #fff;
  margin: 14px 0 0;
}
.dashboad-box .col-md-4:nth-child(2) .dashboard-inner {
  background: rgb(62, 67, 150);
}
.progress {
  transition: 0.5s;
}
.log-logo {
  text-align: center;
  padding-bottom: 20px;
}
.login-form label {
  position: static !important;
  color: #000;
}
.login-form {
  padding: 28px 0 0;
}
.profile-view {
  padding: 50px 20px;
}
.inner-agent {
  max-width: 1010px;
  border-radius: 10px;
  margin: 0 auto;
  display: table;
  background: #1f2a40;
  width: 100%;
  padding: 0 0 30px;
  overflow: hidden;
}
.inner-agent h4 {
  background: #3e4396;
  color: #fff;
  text-align: center;
  font-weight: 600;
  padding: 12px 0;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.inner-agent img {
  max-width: 120px;
  min-height: 120px;
  max-height: 120px;
  min-width: 120px;
  border-radius: 100px;
  object-fit: cover;
  background-color: #3e4396;
  border: 1px solid #ccc;
}
.inner-agent ul {
  padding: 18px 30px 0;
  margin: 0;
}
.inner-agent ul li {
  list-style: none;
  margin-bottom: 10px;
  display: flex;
  color: #fff;
  font-size: 15px;
}
.inner-agent ul li b {
  min-width: 91px;
}
.inner-agent h2 {
  color: #fff;
  font-size: 20px;
  background: #3e4396;
  text-align: center;
  font-weight: 500;
  padding: 9px 0;
  margin-bottom: 18px;
}
.addresses {
  display: flex;
  flex-wrap: wrap;
}
.addresses div {
  width: 47%;
  background: #141b2d;
  margin-right: 15px;
  padding: 12px;
  margin-bottom: 18px;
}
.overflow-table.agent-table {
  padding: 0 30px;
}
.overflow-table.agent-table h3 {
  text-align: center;
  color: #fff;
}
.map-right {
  border-radius: 20px;
  overflow: hidden;
}

.h-210 {
  min-height: 280px;
  height: 280px;
}
.icon-list li {
  list-style: none;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  padding: 3px 5px;
  font-size: 12px;
}
.icon-list li:hover {
  background: #1f2a40;
}
.more-ic {
  height: 100%;
  position: relative;
}
.icon-list {
  padding: 10px;
  background-color: rgb(62 67 150);
  border-radius: 6px;
  position: absolute;
  left: -18px;
  top: 100%;
  z-index: 999;
  box-shadow: 0px 4px 6px 7px rgba(0, 0, 0, 0.02);
  cursor: pointer;
  width: 104px;
  /* max-height: 140px; */
}
.configList > .item {
  display: grid;
  grid-template-columns: 15% 70% 15%;
  margin-bottom: 25px;
}

.icon-list .react-switch-handle {
  height: 18px !important;
  width: 18px !important;
  top: 4px !important;
}
.icon-list .react-switch-bg {
  height: 25px !important;
  width: 49px !important;
  background: rgb(66 66 66) !important;
  border-radius: 14px;
  padding: 0px 5px !important;
  margin-right: 5px !important;
}
.cursior-pointer {
  cursor: pointer;
}
.delete-modal {
  background: #fff;
}
.cross-btns {
  color: #fff;
  font-size: 29px !important;
  position: absolute;
  right: -3px;
  top: -19px;
  cursor: pointer;
}
.btn-bck {
  position: relative;
}
.btn-bck h1 {
  font-size: 22px;
  text-align: center;
  color: #000;
}
.btn-bck a {
  position: absolute;
  left: 0px;
  top: 3px;
  color: #000;
  font-size: 14px;
}
.form-label label {
  line-height: 16px !important;
}
.eye-pos {
  position: absolute;
  right: 15px;
  top: 14px;
  color: #000;
}
.data-del {
  /* width: 100%; */
  margin-right: 10px;
  text-align: center;
  justify-content: center;
}

.mobile_navbar {
  display: none;
}
.gragh-chrt {
  overflow: hidden;
}
.disable {
  cursor: not-allowed;
}

.cebtertable {
  max-width: 98%;
  margin: 0 auto !important;
  display: table;
  border-radius: 10px;
  overflow: hidden;
}

.td-btn span {
  font-weight: 500;
    background: #3e4396;
    color: #fff;
    padding: 5px 11px;
    border-radius: 4px;
    cursor: pointer;
}
.NotFound i {
  margin: 0 auto;
  display: table;
  font-size: 41px;
  margin-bottom: 11px;
}
.NotFound {
  text-align: center;
  padding: 100px 0;
  font-size: 26px;
}
body div:where(.swal2-container) {
  z-index: 9999;
}
/*-----media css start from here-----*/
@media (max-width: 1600px) {
  .configList > .item {
    grid-template-columns: 0.5fr 2fr 0.5fr;
  }
}
@media (max-width: 1200px) {
  .configList > .item {
    grid-template-columns: 0.5fr 1fr 0.5fr;
  }
}
@media (max-width: 991px) {
  .sidebarcollapse ul li span,
  .sidebarcollapse h6,
  .sidebarcollapse .sidenav-header li {
    display: block;
  }
  .sidenav.fixed-start + .main-content {
    margin-left: 0;
  }
  input.text-start {
    min-width: 84px;
    width: 100%;
  }
  .dateselect_navigation {
    flex-wrap: wrap;
  }
  .dateselect_navigation .main_bar {
    margin-bottom: 10px;
  }
  .mobile_navbar {
    display: block;
  }
  aside.sidenav.navbar.navbar-vertical {
    display: none;
  }
  aside.sidenav.navbar.navbar-vertical span.nav-link-text {
    display: none;
  }
  img.logo {
    max-width: 40px;
  }
  html body .navbar-vertical.navbar-expand-xs.sidebarcollapse {
    max-width: 100%;
    width: 200px;
    z-index: 999999;
    display: block;
    margin: 0 !important;
  }
  .navbar .nav-link {
    font-size: 13px;
  }
  html
    body
    .navbar-vertical.navbar-expand-xs.sidebarcollapse
    span.nav-link-text {
    display: block;
  }
  .mobile_navbar .col-md-6.col-sm-6.col-xs-6 {
    width: 50%;
    max-width: 50%;
    flex: 50%;
  }
  .mobile_navbar {
    padding: 10px;
  }
  .mobile_navbar .sidebarback {
    float: left;
  }
  .sidebarcollapse .sidebarback {
    right: 40%;
    position: relative;
  }
  .overflow-table {
    overflow-x: scroll;
  }
  table.page-table tr td a {
    min-width: 120px;
    display: block;
    text-align: center;
  }
  .icon-list {
    left: -50px;
  }
  .icon-list li a {
    text-align: left !important;
  }
  .inner-form {
    margin-top: 20px;
  }
  .profile-view .col-5.mx-auto.col-xs-12 {
    width: 100%;
  }
  .chart_box {
    height: 300px;
    overflow: hidden;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .inner_btns {
    flex-wrap: wrap;
  }
  .inner_btns {
    flex-wrap: wrap;
    display: block !important;
  }
  .dashboard-inner h5 {
    font-size: 16px;
  }
  .sidebarcollapse ul li span,
  .sidebarcollapse h6,
  .sidebarcollapse .sidenav-header li {
    display: block;
  }
  .dateselect_navigation .d-flex {
    margin-top: 10px;
    display: block !important;
    width: 100%;
  }
  .mobile_navbar {
    display: block;
  }
  aside.sidenav.navbar.navbar-vertical {
    display: none;
  }
  aside.sidenav.navbar.navbar-vertical span.nav-link-text {
    display: none;
  }
  img.logo {
    max-width: 40px;
  }
  html body .navbar-vertical.navbar-expand-xs.sidebarcollapse {
    max-width: 100%;
    width: 200px;
    z-index: 999999;
    display: block;
    margin: 0 !important;
  }
  .navbar .nav-link {
    font-size: 13px;
  }
  html
    body
    .navbar-vertical.navbar-expand-xs.sidebarcollapse
    span.nav-link-text {
    display: block;
  }
  .mobile_navbar .col-md-6.col-sm-6.col-xs-6 {
    width: 50%;
    max-width: 50%;
    flex: 50%;
  }
  .mobile_navbar {
    padding: 10px;
  }
  .mobile_navbar .sidebarback {
    float: left;
    width: 35px;
    height: 35px;
    line-height: 36px;
    font-size: 23px;
    padding: 0;
  }
  .sidebarcollapse .sidebarback {
    position: absolute;
    top: 16px;
    right: 0;
    padding: 0 !important;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 23px;
  }
  .overflow-table {
    overflow-x: scroll;
  }
  table.page-table tr td a {
    min-width: 120px;
    display: block;
    text-align: center;
  }
  .icon-list {
    left: -50px;
  }
  .icon-list li a {
    text-align: left !important;
  }
  .inner-form {
    margin-top: 20px;
  }
  .profile-view .col-5.mx-auto.col-xs-12 {
    width: 100%;
  }
  .inner-agent {
    max-width: 100%;
    display: block;
  }
  .inner-agent img {
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
    min-width: 50px;
    border-radius: 100px;
  }
  .inner-agent .col-4.d-flex.justify-content-center {
    display: block !important;
  }
  .inner-agent ul {
    padding: 0;
    margin: 0;
  }
  .inner-agent ul li {
    font-size: 13px;
    flex-wrap: wrap;
  }
  .inner-agent .successfull.bg-white {
    overflow-x: scroll;
  }
  .inner-agent .col-4 {
    width: 100%;
    max-width: 100%;
    flex: 100%;
  }
  .inner-agent .col-8 {
    width: 100%;
    max-width: 100%;
    flex: 100%;
  }
}

@media (max-width: 576px) {
  .configList > .item {
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }
  .configList > .item .item {
    margin: 0 !important;
  }
  .configList > .item .item button {
    margin-left: 0 !important;
  }
}
